import { useQuery } from '@tanstack/vue-query'
import { useApi } from '@tenant/composables'
import { computed } from 'vue'
export function useSettings(keys = []) {
    const { execute } = useApi('/api/settings', 'GET')
    const settings = computed(() => data.value ?? [])

    const { data } = useQuery({
        queryKey: ['settings', keys],
        queryFn: () =>
            execute({
                queries: {
                    keys,
                },
            }),
        staleTime: Infinity,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    })

    return { settings, getSettings: execute }
}
